import React from "react";
import { graphql } from "gatsby";
import { getParagraph } from '../../helpers/paragraph-helpers'

export const ContentListParagraph = ({ node }) => {
  return (
    <div className="paragraph paragraph-content-list has-background-light">
      <h2 className="title is-2">{node.field_titre}</h2>
      <div className="columns is-centered is-multiline">
        {node.relationships.paragraphs.map((paragraph, index) => (
          <div className="column is-4-widescreen is-6" key={index}>
            {getParagraph(paragraph)}
          </div>
        ))}
      </div>
    </div>
  )
};

export const fragment = graphql`
  fragment ParagraphContentList on paragraph__liste_contenus {
    id
    field_titre
    relationships {
      paragraphs: field_contenus {
        type: __typename
        ...ParagraphImageText
      }
    }
  }
`;
