import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from '../components/seo'
import ShowsList from '../components/shows-list'
import { getParagraph } from "../helpers/paragraph-helpers"

const PageTemplate = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const paragraphs = data.page.relationships.paragraphs.map(getParagraph);

  return (
    <Layout>
      <SEO
          title={data.page.title}
          description={data.page.body ? data.page.body.value : null}
      />
      <div className="container content-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={data.page.title}
          />

          <h1 className="title is-1">{data.page.title}</h1>
        </section>

        {data.page.body && (
          <section className="section page-content">
            <div className="content" dangerouslySetInnerHTML={{ __html: data.page.body.value }}/>
          </section>
        )}
      </div>

      <div className="container paragraphes-list">
        {paragraphs}
      </div>

      <div className="memento">
        <div className="container">
          <section className="section is-medium">
            <ShowsList />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default PageTemplate

export const query = graphql`
  query($internalId: Int!) {
    page: nodePage(status: {eq: true}, drupal_internal__nid: { eq: $internalId }) {
      title
      body {
        value
      }
      created(formatString: "DD MMMM YYYY", locale: "fr")
      relationships {
        paragraphs: field_paragraphes {
          type: __typename
          ... on Node {
            ...ParagraphImageText
            ...ParagraphTextImage
            ...ParagraphImage
            ...ParagraphHighlight
            ...ParagraphContentList  
          }
        }
      }
    }
  }
`
