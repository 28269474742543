import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";

export const ImageParagraph = ({ node }) => (
  <div className="paragraph paragraph-image">
    <Img
      fluid={node.relationships.image.localFile.childImageSharp.fluid}
      alt={node.image.alt}
    />
  </div>
);

export const fragment = graphql`
  fragment ParagraphImage on paragraph__image {
    id
    image: field_image {
      alt
    }
    relationships {
      image: field_image {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 450, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
