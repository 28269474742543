import React from "react";
import { graphql } from "gatsby";

export const HighlightParagraph = ({ node }) => (
  <div className="paragraph paragraph-highlight">
    <div className="content" dangerouslySetInnerHTML={{ __html: node.text.processed }} />
  </div>
);

export const fragment = graphql`
  fragment ParagraphHighlight on paragraph__highlight {
    id
    text: field_texte {
      format
      processed
      value
    }
  }
`;
