import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";

export const TextAndImageParagraph = ({ node }) => (
  <div className="paragraph paragraph-text-and-image">
    <div className="columns">
      <div className="column is-6">
        <div className="content" dangerouslySetInnerHTML={{ __html: node.text.processed }} />
      </div>
      <div className="column is-6">
        <Img
          fluid={node.relationships.image.localFile.childImageSharp.fluid}
          alt={node.image.alt}
        />
      </div>
    </div>
  </div>
);

export const fragment = graphql`
    fragment ParagraphTextImage on paragraph__texte_image {
      id
      text: field_texte {
        format
        processed
        value
      }
      image: field_image {
        alt
      }
      relationships {
        image: field_image {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 300, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
`;
