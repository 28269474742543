import React from "react";
import { ImageAndTextParagraph } from "../components/paragraphs/image-and-text-paragraph";
import { TextAndImageParagraph } from "../components/paragraphs/text-and-image-paragraph";
import { ImageParagraph } from "../components/paragraphs/image-paragraph";
import { HighlightParagraph } from "../components/paragraphs/highlight-paragraph";
import { ContentListParagraph } from "../components/paragraphs/content-list-paragraph";

const components = {
  paragraph__image_texte: ImageAndTextParagraph,
  paragraph__texte_image: TextAndImageParagraph,
  paragraph__image: ImageParagraph,
  paragraph__highlight: HighlightParagraph,
  paragraph__liste_contenus: ContentListParagraph
};

export const getParagraph = node => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type];
    return <ParagraphComponent key={node.id} node={node} />;
  }

  return <p key={node.id}>Unknown type {node.__typename}</p>;
};
